import './style.css';

// add all images in profile-images folder to profileImages array
const profileImages = [];
const profileImageContext = require.context('../../assets/profile-images/', false, /\.(png|jpe?g|svg)$/);
profileImageContext.keys().forEach((imagePath) => {
  const image = profileImageContext(imagePath);
  profileImages.push(image);
});

const ProfileImage = ({
  bot,
  showActiveDot = false,
  onClick
}) => {
  // get profile image for bot if it exists
  const profileImage = (bot && bot.image_index !== -1) ? profileImages[bot.image_index - 1] : null;

  return (
    <div className="profile-image-wrapper" onClick={onClick}>
      <div className="profile-image-container">
        {profileImage ? <img src={profileImage} alt="Profile" className="profile-image" /> : null}
      </div>
      {showActiveDot ? <div className="online-dot"></div> : null}
    </div>
  );
};

export default ProfileImage;