// src/helpers.js

export function debounce(func, delay) {
  let timeoutId;
    return function (...args) {
    clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export function wait(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getUrlBotName() {
  const path = window.location.pathname;

  let botName = null;
  if (path && path.length > 1) {
    const mainPath = path.substring(1).trim();

    if (mainPath && mainPath.length > 0) {
      botName = mainPath;
    }
  }

  return botName;
}

// ensure no trailing equals signs in params
export function cleanUrlParams(url) {
  const updatedParams = [];
  for (const [key, value] of url.searchParams.entries()) {
    // Only append the value if it's not empty
    updatedParams.push(value ? `${key}=${value}` : key);
  }

  // Join the updated parameters
  const params = updatedParams.join("&").trim();

  // set the search of params exist
  url.search = (params ? '?' + params : '');
}

export function updateUrlPath(value) {
  if (value) {
    const url = new URL(window.location.href);
    // update path with new value (specifies bot id)
    url.pathname = '/' + value;
    
    url.searchParams.delete('b'); // clear legacy bot serach param - TODO: remove in future
    cleanUrlParams(url); // remove equals for empty params

    // update url
    window.history.pushState({}, '', url.toString());
  }
}

export function updateUrlParameter(name, value) {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  searchParams.set(name, value);

  // update url parameter with new value
  url.search = searchParams.toString();
  url.search = url.search.replace(/=&/g, '&'); // remove empty params
  if (url.search.slice(-1) === '=') {
    url.search = url.search.slice(0, -1); // remove trailing empty param
  }
  
  // update url
  window.history.pushState({}, '', url.toString());
}

export function isEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }
  if (!obj1 || !obj2) {
    return false;
  }
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}