import React, { useRef } from "react";
import './style.css';

import ProfileImage from '../ProfileImage';

import { ReactComponent as MenuIcon } from '../../assets/icons/menu-solid.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-solid.svg';
import { ReactComponent as DownArrow } from '../../assets/icons/arror-down-solid.svg';

const Header = ({
  bot,
  onProfileHeaderClick,
  onMenuClick,
  botListShown,
  showActiveDot = true,
  closeShown = false,
  hideMenu = false,
  onCloseClick,
  headerPerformClose = false,
  headerAllowClick = true,
  hasMultipleBots
}) => {
  const profileInfoShown = useRef(true);

  // set profileLoaded for determining if profile info should be shown
  const profileLoaded = bot && bot.id && profileInfoShown.current;

  const headerClickable = headerAllowClick && (hasMultipleBots || headerPerformClose);

  const handleProfileClick = (event) => {
    if (headerClickable) {
      if (headerPerformClose) {
        handleCloseClick(event);
      }
      if (hasMultipleBots) {
        onProfileHeaderClick(event);
      }
    }
  };

  const handleMenuClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onMenuClick();
  };

  const handleCloseClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onCloseClick();
  };

  return (
    <div className="header">
      <div className={`header-left ${profileLoaded ? 'shown' : ''} ${headerClickable ? 'clickable' : ''}`} onClick={handleProfileClick}>
        <div className={`profile-header ${botListShown ? 'bot-list-shown' : ''}`}>
          { (headerClickable && hasMultipleBots) ? (<div className={`button dropdown-button ${botListShown ? 'dropdown-open' : ''}`}>
            <DownArrow className="button-icon" />
          </div>) : null}
          {profileLoaded ? (
            <div className="profile-header-content">
              <ProfileImage bot={bot} showActiveDot={showActiveDot} />
              <div className="header-info">
                <div className="header-heading">
                  <div className="header-heading-element">{bot ? bot.name : ''}</div>&nbsp;
                </div>
                {(bot && bot.description) ? <div className="header-description">
                  <div className="header-description-element">{bot.description}</div>&nbsp;
                </div> : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="header-right">
        {!botListShown ? <button className="header-button menu-button" onClick={closeShown ? handleCloseClick : handleMenuClick}>
          {closeShown ?
            <CloseIcon className="header-button-icon button-icon menu-icon" />
          : (!hideMenu ? <MenuIcon className="header-button-icon button-icon menu-icon" /> : null)
          }
        </button> : null}
      </div>
    </div>
  );
};

export default Header;