// src/MessageInput.js
import React, { useState, useEffect } from 'react';
import './style.css';

import { ReactComponent as PaperPlaneIcon } from '../../assets/icons/paper-plane-solid.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu-solid.svg';

const MessageInput = ({
  input,
  hasInput,
  onInputChange,
  onKeyDown,
  onSend,
  onToggleTextArea,
  useTextArea,
  loggedWarningShown
}) => {

  const loggedWarning = "Conversations may be reviewed to improve this service.";

  const [allowSelection, setAllowSelection] = useState(true); // allow selection of text in input field (toggled off when not focused to allow select all to avoid selecting input placeholder text)

  // prevent input placeholder text being selectable when text input is not focused
  const handleFocus = () => {
    setAllowSelection(true);
  };
  const handleBlur = () => {
    setAllowSelection(false);
  };

  return (
    <div className={`input-area ${useTextArea ? 'text-area-mode' : ''}`}>
      <button
        className={`text-area-button ${useTextArea ? 'active' : ''}`}
        onClick={onToggleTextArea}
      >
        <div className="text-area-button-icon-region">
          <MenuIcon className="text-area-button-icon" />
        </div>
      </button>
      <div className={`text-region ${!allowSelection ? 'disable-selection' : ''}`}>
        {useTextArea ? (
          <textarea
            className="text-area"
            value={input}
            rows={6}
            onChange={(e) => onInputChange(e.target.value)}
            onKeyDown={onKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder="Type your message..."
            autoFocus={true}
          />
        ) : (
          <input
            className="input-field"
            value={input}
            onChange={(e) => onInputChange(e.target.value)}
            onKeyDown={onKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder="Type your message..."
            autoFocus={true}
          />
        )}
        <button
          className={`send-button${hasInput ? ' active' : ''}`}
          onClick={onSend}
        >
          <PaperPlaneIcon className="button-icon" />
        </button>
      </div>
      { loggedWarningShown ? <div className="logging-warning">{loggedWarning}</div> : null }
    </div>
  );
}

export default MessageInput;