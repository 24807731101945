import React, { useState, useEffect, useRef } from "react";

import "./style.css";

import Header from '../Header';
import MessageList from '../MessageList';
import MessageInput from '../MessageInput';
import BotsView from '../BotsView';
import ConversationBar from '../ConversationBar';
import BotEditView from '../BotEditView';
import Menu from '../Menu';

import useChat from '../../hooks/useChat';
import { debounce, getUrlBotName, getParameterByName, updateUrlParameter, updateUrlPath, isEqual } from '../../utils/helpers';
import { isMobile } from '../../config/config';

function App() {
  const [input, setInput] = useState('');
  const [hasInput, setHasInput] = useState(false);
  const [loggedWarningShown, setLoggedWarningShown] = useState(true);
  
  const [botUserPrefs, setBotUserPrefs] = useState({});
  const prevBotUserPrefs = useRef(null); // store previous bot user prefs to ensure not updating other props when not changed and creating an infinite loop
  const botUserPrefsServerUpdate = useRef(false); // flag to prevent infinite loop when updating bot user prefs from server

  const [userSettings, setUserSettings] = useState({});
  const prevUserSettings = useRef(null); // store previous bot user prefs to ensure not updating other props when not changed and creating an infinite loop
  const userSettingsServerUpdate = useRef(false); // flag to prevent infinite loop when updating bot user prefs from server
  
  const [savingBot, setSavingBot] = useState(false)

  const [useTextArea, setUseTextArea] = useState(false);
  const [hasMultipleBots, setHasMultipleBots] = useState(false);

  const [botListShown, setBotListShown] = useState(false);
  const [menuShown, setMenuShown] = useState(false);

  const [creatingBot, setCreatingBot] = useState(false);
  const [botEditViewShown, setBotEditViewShown] = useState(false);

  const [headerBot, setHeaderBot] = useState(null);

  const [headerStyle, setHeaderStyle] = useState(null);
  const [titleFormat, setTitleFormat] = useState(null);
  
  const inputRef = useRef();
  
  const inputEndRef = useRef(null);

  const [debugMode, setDebugMode] = useState(window.location.search.includes('debug'));

  const [showDebugOverlay, setShowDebugOverlay] = useState(window.location.search.includes('overlay'));
  
  const [darkMode, setDarkMode] = useState(window.location.search.includes('dark'));

  let requestedBots = getParameterByName('bs');
  let requestedBot = getParameterByName('b');

  let requestedBotName = getUrlBotName();

  const sentBotsRequest = useRef(false);
  
  const botChanged = useRef(false); // flag for if bot changed so not updating url with bot id on first load

  const loadBot = (botId) => {
    botChanged.current = true;

    selectBotById(botId);
  };

  const displayError = (errorMessage) => {
    window.setTimeout(() => {
      window.alert(errorMessage)
    }, 100);
  };

  // get properties from useChat hook
  const {
    user,
    curModel,
    messages,
    bot,
    bots,
    userBots,
    groupBots,
    conversation,
    conversations,
    selectBot,
    selectBotById,
    selectBotByName,
    selectConversation,
    updateBotUserPrefs,
    updateUserSettings,
    updateBot,
    createBot,
    debugText,
    sendMessage,
    newChat
  } = useChat(
    input,
    setInput,
    debugMode,
    requestedBots,
    loadBot,
    setSavingBot,
    setBotEditViewShown,
    displayError,
    botUserPrefs,
    setBotUserPrefs,
    setDarkMode
  );

  // on bot changed
  useEffect(() => {
    if (bot) { // if bot is set
      setHeaderBot(bot); // set header bot
      
      // update page title with bot name
      if (bot.name && bot.name.length) {
        const capitalisedName = bot.name.charAt(0).toUpperCase() + bot.name.slice(1);

        let newTitle = capitalisedName;
        if (titleFormat === 'botDescriptionOnly' && bot.description) {
          newTitle = bot.description;
        } else {
          newTitle += " -  BlitBots.com";
        }
        document.title = newTitle;
      }

      // add bot id to url if bot id has changed
      if (botChanged.current) {
        updateUrlPath(bot.idName);
        // updateUrlParameter('b', bot.id);
      }
    }
  }, [bot]);

  // on user settings or darkMode changed
  useEffect(() => {
    if (!isEqual(userSettings, prevUserSettings.current)) { // if bot user prefs has changed
      updateUserSettings(userSettings); // update bot user prefs on server
      if (darkMode !== userSettings.darkMode) { // if useTextArea has changed
        userSettingsServerUpdate.current = true; // set flag to prevent infinite loop
        setDarkMode(userSettings.darkMode); // update useTextArea
      }

      prevUserSettings.current = userSettings; // update previous bot user prefs for future change comparison
    }
  }, [userSettings, darkMode]);

  // on darkMode changed
  useEffect(() => {
    if (userSettingsServerUpdate.current) { // if bot user prefs has been updated on server avoid infinite loop
      userSettingsServerUpdate.current = false; // reset flag
    } else {
      setUserSettings((prevSettings) => { // update bot user prefs with new useTextArea value
        return { ...prevSettings, darkMode: darkMode };
      })
    }
  }, [darkMode]);


  // on bot user prefs or useTextArea changed
  useEffect(() => {
    if (!isEqual(botUserPrefs, prevBotUserPrefs.current)) { // if bot user prefs has changed
      updateBotUserPrefs(botUserPrefs); // update bot user prefs on server
      if (useTextArea !== botUserPrefs.useTextArea) { // if useTextArea has changed
        botUserPrefsServerUpdate.current = true; // set flag to prevent infinite loop
        setUseTextArea(botUserPrefs.useTextArea); // update useTextArea
      }

      prevBotUserPrefs.current = botUserPrefs; // update previous bot user prefs for future change comparison
    }
  }, [botUserPrefs, useTextArea]);

  // on useTextArea changed
  useEffect(() => {
    if (botUserPrefsServerUpdate.current) { // if bot user prefs has been updated on server avoid infinite loop
      botUserPrefsServerUpdate.current = false; // reset flag
    } else {
      setBotUserPrefs((prevSettings) => { // update bot user prefs with new useTextArea value
        return { ...prevSettings, useTextArea: useTextArea };
      })
    }
  }, [useTextArea]);

  // on bots changed
  useEffect(() => {
    setHasMultipleBots(bots && bots.length > 1); // set hasMultipleBots flag for bots dropdown button to show
  }, [bots]);

  const updatePageUrl = () => {
    if (requestedBotName) {
      loadBotByName(requestedBotName);
    } else if (requestedBot) { // if requested bot is set (from url params)
      loadBot(requestedBot); // load requested bot
    } else if (requestedBots && requestedBots.length) {
      const bots = requestedBots.split('-');
      if (bots.length) {
        loadBot(bots[0]); // load first requested bot
      }
    }
    sentBotsRequest.current = true;
  }

  // on bots changed
  useEffect(() => {
    if (bots && !sentBotsRequest.current) { // if bots are set and bots request hasn't been sent
      // setHeaderBot(null); // TODO: clear bot when loading new bot - but ensure doesn't end up with no header bot if load fails

      setTimeout(updatePageUrl, 50); // ensure header has time to clear and not stay cleared - TODO: do this in a better way
    }
  }, [bots]);
  
  // on input changed
  useEffect(() => {
    inputRef.current = input; // store input in ref to ensure latest value is used in delayed send button activate debounce
  }, [input]);

  // on messages changed
  useEffect(() => {
    inputEndRef.current.scrollIntoView({ block: 'end' }); // scroll to bottom of page when new message received
  }, [messages, inputEndRef]);

  const loadBotByName = (requestedBotName) => {
    selectBotByName(requestedBotName);

    const largeHeaderBots = [
      'gates-librarian'
    ];
    const titleDescriptionOnlBots = [
      'gates-librarian'
    ];

    if (largeHeaderBots.includes(requestedBot+'')) { // Custom Bot
      setHeaderStyle('large-header');
    }
    if (titleDescriptionOnlBots.includes(requestedBot+'')) { // Custom Bot
      setTitleFormat('botDescriptionOnly');
    }
  };
  
  // on message input changed
  const handleInputChange = () => {
    let newHasInput = input.trim().length > 0;
    if (newHasInput) { // if input has text
      debounce(() => { // debounce to add delay to send button activation
        const currentInput = inputRef.current;
        const currentHasInput = currentInput.trim().length > 0;
        setHasInput(currentHasInput); // activate send button if input still has text
      }, 250)();
    } else { // if input is empty
      setHasInput(newHasInput); // deactivate send button immediately
    }
  };
  
  // on input changed
  useEffect(() => {
    handleInputChange(); // handle message input change for activating send button
  }, [input]);

  // on component mounted
  useEffect(() => {
    // on key up event
    const handleWindowKeyUp = (event) => {
      // on ctrl-B
      if (event.code === 'KeyB' && event.ctrlKey) {
        // toggle debug overlay
        setShowDebugOverlay((value) => !value);
      }

      // on ctrl-shift-K
      if (event.code === 'KeyK' && event.ctrlKey && event.shiftKey) {
        // toggle dark mode
        setDarkMode((value) => !value);
      }
    }

    // on window navigate, update url params and reload bot
    const handlWindowNavigate = () => {
      setDebugMode(window.location.search.includes('debug'));
      setShowDebugOverlay(window.location.search.includes('overlay'));
      setDarkMode(window.location.search.includes('dark'));
    
      requestedBots = getParameterByName('bs');
      requestedBot = getParameterByName('b');
      requestedBotName = getUrlBotName();

      botChanged.current = false;
      updatePageUrl();
    }

    // add event listener for key up event
    window.addEventListener('keyup', handleWindowKeyUp);
    window.addEventListener('popstate', handlWindowNavigate);
    
    // clean up function to remove event listener on unmount
    return () => {
      window.removeEventListener('keyup', handleWindowKeyUp);
      window.removeEventListener('popstate', handlWindowNavigate);
    };
  }, []); // run the effect only once on mount

  // handle message input key down event
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (isMobile) { // if mobile
        if (!useTextArea || event.ctrlKey) { // if not using text area or ctrl key is pressed
          event.preventDefault();
          handleSubmit(); // send message
        }
      } else { // is desktop
        if (!event.shiftKey) { // if shift key is not pressed
          event.preventDefault();
          handleSubmit(); // send message
        }
      }
    }
  };

  // handle sending message and hiding conversations logged warning
  const handleSubmit = () => {
    if (input) {
      sendMessage();

      setLoggedWarningShown(false); // hide conversations logged warning
    }
  }

  // handle toggle text area button click
  const handleToggleTextArea = () => {
    // toggle showing full text area
    setUseTextArea((prevValue) => !prevValue);
  };

  // handle profile header click
  const handleProfileHeaderClick = () => {
    // toggle showing bot list view
    setBotListShown((prevValue) => !prevValue);
  };

  // handle menu click
  const handleMenuClick = () => {
    // toggle showing menu
    setMenuShown((prevValue) => !prevValue);
  };

  // handle menu triggering close
  const handleMenuTriggerClosed = () => {
    // hide menu
    setMenuShown(false);
  };

  // handle header close click
  const handleHeaderCloseClick = () => {
    if (botEditViewShown) {
      setBotEditViewShown(false);
    } else if (menuShown) {
      setMenuShown(false);
    }
  };

  // on select bot from bot list
  const handleSelectBot = (newBot) => {
    // hide bot list view
    setBotListShown(false);

    // if bot has changed
    if (!bot || newBot.id !== bot.id) {
      loadBot(newBot.id)

      // set header bot as selected bot (so that it displays bot until it's loaded)
      setHeaderBot(newBot);
    }
  };

  // on select conversation from conversation list
  const handleSelectConversation = (conversation) => {
    // select conversation from server
    selectConversation(conversation);
  };

  // on handle save bot from bot edit view
  const handleSaveBot = (botDetails) => {
    if (creatingBot) {
      createBot(botDetails);
    } else {
      if (bot && bot.id) {
        updateBot(bot.id, botDetails);
      }
    }

    setSavingBot(true);
    // setBotEditViewShown(false);
  };

  // on select menu item
  const handleSelectMenuItem = (menuItem) => {
    // TODO: handle menu item selection
    switch (menuItem.action) {
      case 'darkMode':
        setDarkMode((prevValue) => !prevValue);
        break;
      case 'editBot':
        setCreatingBot(false);
        setBotEditViewShown(true);
        break;
      case 'createBot':
        setCreatingBot(true);
        setBotEditViewShown(true);
        break;
      case 'selectBot':
        loadBot(menuItem.botId);
        break;
    }

    // hide menu
    setMenuShown(false);
  };

  // toggle debug overlay on error message click
  const handleErrorMessageClick = () => {
    if (debugMode) {
      setShowDebugOverlay((value) => !value);
    }
  };

  return (
    <div className="container">
      <div className={`chat-window ${headerStyle} ${darkMode ? 'dark-mode' : ''}`}>
        <Header
          bot={headerBot}
          onProfileHeaderClick={handleProfileHeaderClick}
          onMenuClick={handleMenuClick}
          botListShown={botListShown}
          showActiveDot={!botListShown && !botEditViewShown}
          closeShown={menuShown}
          hideMenu={botEditViewShown}
          onCloseClick={handleHeaderCloseClick}
          headerAllowClick={!botEditViewShown}
          hasMultipleBots={hasMultipleBots}
        />
        {(debugMode && showDebugOverlay) ? <div className="debug-text">{debugText}</div> : null}
        <ConversationBar
          shown={bot && bot.id && !botListShown && (conversation || (conversations && conversations.length > 0))}
          conversation={conversation}
          conversations={conversations}
          messages={messages}
          botListShown={botListShown}
          onSelectConversation={handleSelectConversation}
          onNewChat={newChat}
        />
        <MessageList
          messages={messages}
          model={curModel}
          bot={bot}
          onErrorClick={handleErrorMessageClick}
        />
        <MessageInput
          input={input}
          useTextArea={useTextArea}
          hasInput={hasInput}
          loggedWarningShown={loggedWarningShown}
          onInputChange={(value) => {
            setInput(value);
          }}
          onKeyDown={handleKeyDown}
          onSend={handleSubmit}
          onToggleTextArea={handleToggleTextArea}
        />
        <div ref={inputEndRef}></div>
        {botListShown ? <BotsView bots={bots} curBot={bot} selectBot={handleSelectBot} /> : null}
        {botEditViewShown ? <BotEditView
          creatingBot={creatingBot}
          curBot={bot}
          isAdmin={user && user.isAdmin}
          triggerSaveBot={handleSaveBot}
          saving={savingBot}
          triggerClose={handleHeaderCloseClick}
        /> : null}
        {(menuShown && !botListShown) ? <Menu
          settings={{darkMode: darkMode, user: user}}
          curBot={bot}
          userBots={userBots}
          groupBots={groupBots}
          onSelectMenuItem={handleSelectMenuItem}
          triggerClosed={handleMenuTriggerClosed}
        /> : null}
      </div>
    </div>
  );
}

export default App;
