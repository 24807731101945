import React from 'react';
import './style.css';

import BotListItem from '../../BotsView/BotListItem';

const MenuItem = ({
  curBot,
  menuItem,
  onSelect,
  className
}) => {

  const isActiveBot = menuItem.bot && curBot && curBot.id === menuItem.bot.id;
  const isClickable = menuItem.action;

  const handleClick = () => {
    onSelect(menuItem);
  };

  return (
    <div className={`menu-item ${className || ''} ${isClickable ? 'clickable' : ''} ${menuItem.bot ? 'is-bot' : ''}`} onClick={isClickable ? handleClick : null}>
      {menuItem.action === 'selectBot' ? (
        <BotListItem bot={menuItem.bot} isActive={isActiveBot} />
      ) : (
        <div className="menu-item-label">
          {menuItem.label}{menuItem.value ? <span className='menu-item-value'>{menuItem.value}</span> : ''}
        </div>
      )}
    </div>
  );
};

export default MenuItem;