import React from 'react';
import './style.css';

import ConversationListItem from './ConversationListItem';

const ConversationView = ({ conversations, curConversation, onSelectConversation, conversationListContainerRef }) => {
  const conversationCount = conversations ? conversations.length : 0;
  
  return (
    <div className="conversation-view">
      <div className="conversation-view-container" ref={conversationListContainerRef}>
        {conversations.map((conversation, index) => (
          <ConversationListItem
            key={conversation.id}
            number={conversationCount - index}
            isActive={curConversation && curConversation.id === conversation.id}
            conversation={conversation}
            onSelectConversation={onSelectConversation}
          />
        ))}
      </div>
    </div>
  );
};

export default ConversationView;