import React, { useState, useEffect, useRef } from 'react';
import './style.css';

import ConversationView from './ConversationView';

import { defaultChatName, newChatName } from '../../config/locale';

import { ReactComponent as DownArrow } from '../../assets/icons/arror-down-solid.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus-solid.svg';
import { ReactComponent as OptionsIcon } from '../../assets/icons/options-solid.svg';

const ConversationBar = ({
  shown,
  conversation,
  conversations,
  messages,
  onSelectConversation,
  onNewChat,
  botListShown
}) => {
  const conversationBarRef = useRef();
  const conversationListContainerRef = useRef();
  const conversationListOpenRef = useRef();

  // set multiple conversations for determining if dropdown button should be shown
  const hasMultipleConversations = conversations && conversations.length && (!conversation || conversations.filter(conversationId => conversationId.id !== conversation.id).length >= 1);

  // set hasUserMessage for determining if new chat button should be shown
  const hasUserMessage = messages.some(message => message.role === 'user');

  const [conversationListOpen, setConversationListOpen] = useState(false);

  const [headerConversation, setHeaderConversation] = useState(false); // set conversation selected from dropdown to avoid new chat appearing during conversation load - TODO: should be null by default
  const [isConversationLoading, setIsConversationLoading] = useState(false); // flag to prevent header from being set to null (and flashing new chat) while conversation is loading
  
  // if getting default name, use 'Chat' + number of existing conversations
  const getConversationDefaultName = () => {
    if (headerConversation && conversations) {
      const curConversationItem = conversations.find(item => item.id == headerConversation.id);
      if (curConversationItem) {
        const curConversationIndex = conversations.indexOf(curConversationItem);
        if (curConversationIndex !== -1) {
          const curConversationNumber = conversations.length - curConversationIndex;
          const conversationDefaultName = defaultChatName + ' ' + curConversationNumber;
          return conversationDefaultName;
        }
      }
    }
    return defaultChatName;
  }

  // set conversation name based on header conversation
  const conversationName = headerConversation ? (headerConversation.name ? headerConversation.name : getConversationDefaultName()) : newChatName;

  // on conversation change, set header conversation to new conversation
  useEffect(() => {
    if (!isConversationLoading) { //prevent header from being set to null (and flashing new chat) while conversation is loading
      setHeaderConversation(conversation);
    }
  }, [conversation]);

  // on bot list shown change, close conversation list
  useEffect(() => {
    if (botListShown) {
      closeConversationList();
    }
  }, [botListShown]);

  // on conversation list open change, set ref for determining if clicking outside of conversation list should close it
  useEffect(() => {
    conversationListOpenRef.current = conversationListOpen;
  }, [conversationListOpen]);

  // on click outside of conversation list, close conversation list
  const handleClickOutside = (event) => {
    if (conversationListOpenRef.current
      && (!conversationBarRef.current || !conversationBarRef.current.contains(event.target))
      && (!conversationListContainerRef.current || !conversationListContainerRef.current.contains(event.target))
    ) {
      setConversationListOpen(false);
    }
  }
  
  // on mount, add click and touch listeners to document for closing conversation list
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);


  // on click of dropdown button, toggle conversation list
  const toggleDropdown = () => {
    setConversationListOpen(!conversationListOpen);
  };

  // on click of conversation list item, set header conversation, close conversation list and load conversation
  const handleSelectConversation = (newConversation) => {
    closeConversationList();

    if (!conversation || newConversation.id !== conversation.id) {
      setIsConversationLoading(true);

      onSelectConversation(newConversation); // load conversation
      setHeaderConversation(newConversation); // set conversation displayed in conversation bar header while conversation is loading
      
      setTimeout(() => {
        setIsConversationLoading(false);
      }, 10); // To prevent conversation being set to null clearing name in conversation bar - TODO: find a better way to do this
    }
  }

  // close conversation list
  const closeConversationList = () => {
    if (conversationListOpen) {
      setConversationListOpen(false);
    }
  }

  // on click of new chat button, close conversation list and load new chat
  const handleNewChat = () => {
    closeConversationList();
    onNewChat();
  }

  return (
    <div className="conversation-bar">
      {conversationListOpen && (
        <ConversationView
          conversationListContainerRef={conversationListContainerRef}
          conversations={conversations}
          curConversation={headerConversation}
          onSelectConversation={handleSelectConversation}
        />
      )}
      <div className={`conversation-bar-header ${shown ? 'shown' : ''}`} ref={conversationBarRef}>
        <div className={`left-controls ${hasMultipleConversations ? 'active' : ''} ${conversationListOpen ? 'conversation-list-shown' : ''}`} onClick={hasMultipleConversations ? toggleDropdown : null}>
          { hasMultipleConversations ? (
            <div className={`button dropdown-button ${conversationListOpen ? 'dropdown-open' : ''}`}>
              <DownArrow className="button-icon" />
            </div>
          ) : null }
          <div className={`conversation-label ${!headerConversation ? 'new-chat' : ''}`} onClick={hasMultipleConversations ? toggleDropdown : null}>
            {conversationName}
          </div>
        </div>
        <div className="right-controls">
          { (hasMultipleConversations && headerConversation) || hasUserMessage ? (
            <button className="button new-chat-button" onClick={handleNewChat}>
              <div className="button-label">New chat</div>
              <div className="button-icon-container">
                <PlusIcon className="button-icon plus-icon" />
              </div>
            </button>
          ) : null }
          {/* <button className="button options-button" onClick={onMenuClick}>
            <OptionsIcon className="button-icon" />
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default ConversationBar;