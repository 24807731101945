// src/useChat.js
import { useRef, useState, useEffect, useCallback } from 'react';

import { wait, getParameterByName } from '../utils/helpers';

import { apiEndpoint } from '../config/config';

const useChat = (
  input,
  setInput,
  debugMode,
  requestedBots,
  loadBot,
  setSavingBot,
  setBotEditViewShown,
  displayError,
  botUserPrefs,
  setBotUserPrefs,
  setDarkMode
) => {
  const [sessionId, setSessionId] = useState(null);
  
  // allow override of default gpt-4 model by specifiying gpt-3.5 in the url e.g. /?gpt-3.5
  const providedModel = window.location.search.includes('gpt-4') ? 'gpt-4' : (window.location.search.includes('gpt-3.5') ? 'gpt-3.5' : 'gpt-4');
  const [model] = useState(providedModel); // OpenAI model (based provided url param or default)
  const [curModel, setCurModel] = useState(providedModel); // current OpenAI model from server (may fallback if credit used)
  
  const [user, setUser] = useState(null)

  const [bot, setBot] = useState(null)
  const [bots, setBots] = useState(null);
  const [userBots, setUserBots] = useState(null);
  const [groupBots, setGroupBots] = useState(null);

  const [conversation, setConversation] = useState(null);
  const [conversations, setConversations] = useState(null);

  const [messages, setMessages] = useState([]);
  const [welcomeMessage, setWelcomeMessage] = useState(null);

  const [debugText, setDebugText] = useState('');
  
  const hasLoaded = useRef(false);

  const curBot = useRef(null);
  const curConversation = useRef(null);

  const modelWarningShown = useRef(false);

  const group = getParameterByName('a'); // get requested user group from url param
  const [extend] = useState(window.location.search.includes('extend'));

  const requestedUser = getParameterByName('u'); // get requested user from url param

  useEffect(() => {
    curBot.current = bot; // track current bot for checking if still on correct bot in sendMessage callback
  }, [bot]); // on bot changed
  useEffect(() => {
    curConversation.current = conversation; // track current conversation for checking if still on correct bot in sendMessage callback
  }, [conversation]); // on conversation changed

  useEffect(() => {
    const settings = user && user.settings;
    if (settings) {
      setDarkMode(settings.darkMode || false);
    }
  }, [user]); // on user changed
  
  // add a welcome message to displayed messages list for new or loaded chat
  const addWelcomeMessage = useCallback((welcomeMessage) => {
    setWelcomeMessage(welcomeMessage);
    if (welcomeMessage) {
      setMessages([{ role: "assistant", appended: true, content: welcomeMessage }]);
    } else {
      setMessages([]);
    }
  }, [setWelcomeMessage, setMessages]); // callback dependencies

  // set respective properties when loading a session, selecting a bot or selecting a conversation (selectingConversation = true)
  const loadConversation = useCallback((data, selectingConversation) => {
    setConversation(data.conversation);

    // don't set bot and conversation list when selecting conversation (only do this when loading session or bot)
    if (!selectingConversation) {
      setBot(data.bot);
      setConversations(data.conversations);
      if (data.botUserPrefs) {
        setBotUserPrefs(data.botUserPrefs);
    }
    }

    if (data.messages && data.messages.length) { // display messages returned from server
      setMessages(data.messages);
    } else if (data.welcomeMessage) { // if no messages returned, but we have a welcome message, then show this
      addWelcomeMessage(data.welcomeMessage);
    }
  }, [setBot, setConversation, setMessages, addWelcomeMessage]); // callback dependencies

  // get plugin from bot by name if it exists
  const getPlugin = (bot, pluginName) => {
    if (bot && bot.plugins && bot.plugins.plugins && bot.plugins.plugins.length) {
      const plugins = bot.plugins.plugins;
      const curPlugin = plugins.find(plugin => plugin.name === pluginName);
      if (curPlugin && curPlugin.enabled != false) {
        return curPlugin;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  
  // get or create session from server
  // does not create a new user, conversation or add any messages to the database (this happens only on send)
  const getSession = useCallback(async () => {
    let responseText = null;
    let data = null;

    // error variables
    let sendError = false;
    let sendErrorMsg = null;
    let sendErrorUserMsg = null;

    try {
      // getSession request arguments
      const body = {
        action: 'getSession',
        model: model,
        timezoneOffset: encodeURIComponent(new Date().getTimezoneOffset()) // provde client timezone offset to server so bot is aware of user's time
      };
      if (requestedUser) {
        body.user = requestedUser; // requested user group
      }
      if (group) {
        body.group = group; // requested user group
      }
      if (extend) {
        body.extend = true; // TODO: remove - for testing only
      }
      if (requestedBots && requestedBots.length) { // send requested bots to server
        body.bots = (requestedBots && requestedBots.length ? requestedBots : null);
      }

      if (debugMode) { // request debug info from server
        body.debug = debugMode; // TODO: remove - for testing only
      }

      // send getSession request to server
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      
      responseText = await response.text();
      data = JSON.parse(responseText);

      // set error states and log if appropriate
      if (!data.error) {
        if (debugMode) {
          console.log('getSession RESPONSE data:', data);
          setDebugText(data.debugString);
        }
      } else {
        sendErrorUserMsg = data.error;
        sendError = true;
        sendErrorMsg = data.error;
        if (debugMode) {
          setDebugText(sendErrorMsg + " " + data.debugString);
        }
      }
    } catch (error) {
      console.error(responseText);
      console.error(error);
      sendError = true;
      sendErrorMsg = error;
    }

    // if error occured
    if (!data || sendError) {
      let errorMessage = "An error occurred connecting. Please try later.";

      if (sendErrorUserMsg) { // use custom error message from server if provided
        errorMessage = sendErrorUserMsg;
      }
      
      // display error in chat view
      setMessages((prevMessages) => [...prevMessages, { role: "error", appended: true, content: errorMessage }]);
    } else {
      // set current session id for debugging on localhost, not used on server
      setSessionId(data.sessionId); // TODO: remove storing session id on front end for localhost and find alternate approach - cookies should work locally, but they don't seem to?
      
      setBots(data.bots); // set list of bots
      setUserBots(data.userBots); // set list of user bots
      setGroupBots(data.groupBots); // set list of user bots

      setUser(data.user); // set user
      
      // load bot info and current conversation / welcome message
      loadConversation(data);
    }
  }, [debugMode, loadConversation, model]); // callback dependencies


  // send message to server and get OpenAI response
  const sendMessage = async () => {
    if (input.trim() !== "") {
      const inputMessage = input.trim();

      // add user message to chat view immediately
      const userMessage = { role: "user", appended: true, content: inputMessage };
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      // add pending assistant loader to chat view
      setMessages((prevMessages) => [...prevMessages, { role: "assistant", appended: true, isPending: true, content: "" }]);

      // clear input immediately
      setInput("");

      // move current conversation to top of conversation list
      if (conversation && conversations && conversations.length) {
        const conversationIndex = conversations.findIndex((c) => c.id === conversation.id);
        if (conversationIndex !== -1) {
          const removedConversation = conversations.splice(conversationIndex, 1)[0];
          conversations.unshift(removedConversation);
          setConversations([...conversations]);
        }
      }

      let responseText = null;

      // error variables
      let sendError = false;
      let sendErrorUserMsg = null;
      let sendErrorMsg = null;

      // sendMessage request arguments
      const body = {
        action: 'sendMessage',
        message: inputMessage,
        model: model, // requested gpt model
        botUserPrefs: botUserPrefs, // current bot user preferences to save initial state e.g. text area shown
        botId: bot && bot.id,
        welcomeMessage: (conversation && conversation.id) ? null : welcomeMessage, // send initial welcome message if conversation does not yet exist
        timezoneOffset: encodeURIComponent(new Date().getTimezoneOffset()) // provde client timezone offset to server so bot is aware of user's time
      };
      if (conversation) { // conversation to send message to (can have multiple conversations open at once)
        body.conversationId = conversation.id;
      }
      if (requestedUser) {
        body.user = requestedUser; // requested user group
      }
      if (group) {
        body.group = group; // requested user group
      }
      if (extend) {
        body.extend = true; // TODO: remove - for testing only
      }

      if (sessionId) {
        body.sessionId = sessionId; // for local debugging, not used on server
      }
      if (debugMode) { // request debug info from server
        body.debug = debugMode; // TODO: remove - for testing only
      }

      try {
        // send sendMessage request to server
        const response = await fetch(apiEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });

        responseText = await response.text();
        const data = JSON.parse(responseText);

        if (debugMode) {
          // log response data for debugging if in debug mode
          console.log('SendMessage RESPONSE data:', data);
          setDebugText(data.debugString);
        }

        // if no error
        if (!data.error) {
          if (debugMode && data.usage) {
            // log OpenAI tokens used by request if in debug mode
            // maximum amount of characters to send to OpenAI is already calculated on the backend to avoid reaching limit
            console.log('transaction tokens: ', data.usage.total_tokens + ' / 8192 (' + Math.round(data.usage.total_tokens / 8192 * 100) + '%)');
          }

          // if response is for current bot and conversation
          const currentBot = curBot.current;
          if (data.bot && currentBot && data.bot.id === currentBot.id) {
            const currentConversation = curConversation.current;

            // get response conversation details (may have updated name)
            const updatedConversation = data.conversation;
            const isNewConversation = (!currentConversation || currentConversation.id === null);

            // if conversation is new or conversation name has updated
            if (isNewConversation || (currentConversation && updatedConversation.id === currentConversation.id)) {
              if (isNewConversation || (currentConversation && updatedConversation.name !== currentConversation.name)) {
                // set current conversation
                setConversation(updatedConversation);

                // if conversation with same id already exists then remove it and add this one
                setConversations((conversations) => {
                  if (!conversations){
                    return [updatedConversation];
                  } else {
                    // want to remove existing so we can replace if name has updated
                    const existingConversationsWithCurrentRemoved = conversations.filter((conversation) => conversation.id !== updatedConversation.id);
                    return [updatedConversation, ...existingConversationsWithCurrentRemoved];
                  }
                });
              }

              // set the currently used model
              setCurModel(data.model);
              // if shown warning about downgrading model
              if (modelWarningShown.current) {
                // if model restored to gpt-4
                if (data.model === "gpt-4") {
                  // unset model warning
                  modelWarningShown.current = false;

                  // add message about restored gpt-4 access
                  setMessages((prevMessages) => [
                    ...prevMessages,
                    { role: "error", appended: true, content: "You are now back to GPT-4 access." }
                  ]);
                }
              } else { // if shown warning not shown
                if (data.warningMessage) { // and response has model downgraded warning message
                  // set model warning
                  modelWarningShown.current = true;
                  
                  // add message about downgraded model access (e.g. model downgraded to gpt-3.5)
                  setMessages((prevMessages) => [
                    ...prevMessages,
                    { role: "error", appended: true, content: data.warningMessage }
                  ]);
                }
              }

              // if response has a message
              if (data.message !== undefined) {
                setMessages((prevMessages) => [
                  // remove any bot pending messages
                  ...prevMessages.filter((message) => !message.isPending),

                  // add bot's response
                  { role: "assistant", appended: true, content: data.message, media: data.media }
                ]);
              }
            }
          }
        } else {
          sendError = true;
          sendErrorUserMsg = data.error;
          sendErrorMsg = (debugMode && data.debugError ? " " + data.debugError : ""); // data.error + 
        }
      } catch (error) {
        console.error(responseText);
        console.error(error);
        sendError = true;
        sendErrorMsg = error;
      }

      // if error has occured
      if (sendError) {
        let errorMessage = "An error occurred while attempting to send message. Please try again.";

        if (sendErrorUserMsg) { // use custom error message from server if provided
          errorMessage = sendErrorUserMsg;
        }

        setMessages((prevMessages) => [
            // remove pending messages
            // and remove last sent message
          ...prevMessages.filter((message) => !message.isPending).slice(0, -1),

          // display error message
          { role: "error", appended: true, content: errorMessage }
        ]);

        // restore previous input to allow user to retry
        setInput(input);
      }
    }
  };


  // clear messages and input
  const clearConversationWindow = () => {
    setMessages([]);
    setConversation(null);
    setInput("");
  }


  // clear chat and start new one (clearing current conversation id on server and getting new message etc.)
  const newChat = async () => {
    let responseText = null;

    // error variables
    let sendError = false;
    let sendErrorMsg;
    let sendErrorUserMsg = null;

    // clear messages and input
    clearConversationWindow();

    try {
      // newChat request arguments
      const body = {
        action: 'newChat',
        botId: bot && bot.id
      };
      if (requestedUser) {
        body.user = requestedUser; // requested user group
      }
      if (group) {
        body.group = group; // requested user group
      }
      if (extend) {
        body.extend = true; // TODO: remove - for testing only
      }

      if (sessionId) {
        body.sessionId = sessionId; // for local debugging, not used on server
      }
      if (debugMode) {
        body.debug = debugMode; // TODO: remove - for testing only
      }
      
      // send newChat request to server
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
      });
      
      responseText = await response.text();
      const data = JSON.parse(responseText);
      
      // if no errors
      if (!data.error) {
        // add welcome message retrieved from server
        addWelcomeMessage(data.welcomeMessage);
  
        if (debugMode) {
          console.log('newChat RESPONSE data:', data);
          setDebugText(data.debugString);
        }
      } else {
        sendErrorUserMsg = data.error;
        sendError = true;
        sendErrorMsg = data.error + (debugMode && data.debugError ? " " + data.debugError : "");
      }
    } catch (error) {
      console.error(responseText);
      console.error(error);
      sendError = true;
      sendErrorMsg = error;
    }

    // if error has occured
    if (sendError) {
      let errorMessage = "An error occurred. Please try again.";

      if (sendErrorUserMsg) { // use custom error message from server if provided
        errorMessage = sendErrorUserMsg;
      }

      // display error in chat view
      setMessages((prevMessages) => [...prevMessages, { role: "error", appended: true, content: errorMessage }]);
    }
  };


  // load bot by bot object
  const selectBot = async (bot) => {
    const botId = bot.id;

    selectBotById(botId);
  }


  // load bot by id
  const selectBotById = async (botId) => {
    selectBotByIdOrName(botId, null);
  }
  const selectBotByName = async (botIdName) => {
    selectBotByIdOrName(null, botIdName);
  }

  const selectBotByIdOrName = async (botId, botIdName = null) => {
    let responseText = null;

    // error variables
    let sendError = false;
    let sendErrorMsg = null;
    let sendErrorUserMsg = null;

    // clear messages and input
    clearConversationWindow();

    // clear current bot
    setBot(null);

    try {
      // selectBotById request arguments
      const body = {
        action: 'selectBot',
      };
      if (botId !== null) {
        body.botId = botId;
      }
      if (botIdName !== null) {
        body.botIdName = botIdName;
      }
      if (requestedUser) {
        body.user = requestedUser; // requested user group
      }
      if (group) {
        body.group = group; // requested user group
      }
      if (extend) {
        body.extend = true; // TODO: remove - for testing only
      }
      
      if (sessionId) {
        body.sessionId = sessionId; // for local debugging, not used on server
      }
      if (debugMode) {
        body.debug = debugMode; // TODO: remove - for testing only
      }

      // send selectBotById request to server
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
      });
      
      responseText = await response.text();
      const data = JSON.parse(responseText);
      
      // if no error
      if (!data.error) {
        // load bot info and current conversation / welcome message
        loadConversation(data);
        
        if (debugMode) {
          console.log('selectBot RESPONSE data:', data);
          setDebugText(data.debugString);
        }
      } else {
        sendErrorUserMsg = data.error;
        sendError = true;
        sendErrorMsg = data.error + (debugMode && data.debugError ? " " + data.debugError : "");
      }
    } catch (error) {
      console.error(responseText);
      console.error(error);
      sendError = true;
      sendErrorMsg = error;
    }

    // if error has occured
    if (sendError) {
      let errorMessage = "An error occurred. Please try again.";

      if (sendErrorUserMsg) { // use custom error message from server if provided
        errorMessage = sendErrorUserMsg;
      }

      // display error in chat view
      setMessages((prevMessages) => [...prevMessages, { role: "error", appended: true, content: errorMessage }]);
    }
  }


  // load previous conversation from conversation list
  const selectConversation = async (conversation) => {
    const conversationId = conversation.id;

    let responseText = null;

    // error variables
    let sendError = false;
    let sendErrorMsg = null;
    let sendErrorUserMsg = null;

    // clear messages and input
    clearConversationWindow();

    try {
      // selectConversation request arguments
      const body = {
        action: 'selectConversation',
        botId: bot && bot.id,
        conversationId: conversationId
      };
      if (requestedUser) {
        body.user = requestedUser; // requested user group
      }
      if (group) {
        body.group = group; // requested user group
      }
      if (extend) {
        body.extend = true; // TODO: remove - for testing only
      }

      if (sessionId) {
        body.sessionId = sessionId; // for local debugging, not used on server
      }
      if (debugMode) {
        body.debug = debugMode; // TODO: remove - for testing only
      }
      
      // send selectConversation request to server
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
      });
      
      responseText = await response.text();
      const data = JSON.parse(responseText);
      
      if (!data.error) {
        // load bot info and current conversation / welcome message
        loadConversation(data, true);
        
        if (debugMode) {
          console.log('selectConversation RESPONSE data:', data);
          setDebugText(data.debugString);
        }
      } else {
        sendErrorUserMsg = data.error;
        sendError = true;
        sendErrorMsg = data.error + (debugMode && data.debugError ? " " + data.debugError : "");
      }
    } catch (error) {
      console.error(responseText);
      console.error(error);
      sendError = true;
      sendErrorMsg = error;
    }

    // if error has occured
    if (sendError) {
      let errorMessage = "An error occurred. Please try again.";

      if (sendErrorUserMsg) { // use custom error message from server if provided
        errorMessage = sendErrorUserMsg;
      }

      // display error in chat view
      setMessages((prevMessages) => [...prevMessages, { role: "error", appended: true, content: errorMessage }]);
      }
  }


  // update user preferences for current bot (e.g. text area expanded)
  const updateBotUserPrefs = async (botUserPrefs) => {
    if (!sessionId) { // if session has not been loaded then return
      return;
    }
    
    let responseText = null;

    // error variables
    let sendError = false;
    let sendErrorMsg = null;
    let sendErrorUserMsg = null;

    try {
      // updateBotUserPrefs request arguments
      const body = {
        action: 'updateBotUserPrefs',
        botId: bot && bot.id,
        botUserPrefs, botUserPrefs
      };
      if (requestedUser) {
        body.user = requestedUser; // requested user group
      }
      if (group) {
        body.group = group; // requested user group
      }
      if (extend) {
        body.extend = true; // TODO: remove - for testing only
      }

      if (sessionId) {
        body.sessionId = sessionId; // for local debugging, not used on server
      }
      if (debugMode) {
        body.debug = debugMode; // TODO: remove - for testing only
      }
      
      // send updateBotUserPrefs request to server
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
      });
      
      responseText = await response.text();
      const data = JSON.parse(responseText);
      
      if (data.error) {
        sendErrorUserMsg = data.error;
        sendError = true;
        sendErrorMsg = data.error + (debugMode && data.debugError ? " " + data.debugError : "");
      }
    } catch (error) {
      console.error(responseText);
      console.error(error);
      sendError = true;
      sendErrorMsg = error;
    }

    // if error has occured
    if (sendError) {
      let errorMessage = "An error occurred. Please try again.";

      if (sendErrorUserMsg) { // use custom error message from server if provided
        errorMessage = sendErrorUserMsg;
      }
    }
  }
  
  // update user settings (e.g. dark mode)
  const updateUserSettings = async (settings) => {
    if (!sessionId) { // if session has not been loaded then return
      return;
    }

    let responseText = null;

    // error variables
    let sendError = false;
    let sendErrorMsg = null;
    let sendErrorUserMsg = null;

    try {
      // updateUserSettings request arguments
      const body = {
        action: 'updateUserSettings',
        settings, settings
      };
      if (requestedUser) {
        body.user = requestedUser; // requested user group
      }
      if (group) {
        body.group = group; // requested user group
      }
      if (extend) {
        body.extend = true; // TODO: remove - for testing only
      }

      if (sessionId) {
        body.sessionId = sessionId; // for local debugging, not used on server
      }
      if (debugMode) {
        body.debug = debugMode; // TODO: remove - for testing only
      }
      
      // send updateUserSettings request to server
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
      });
      
      responseText = await response.text();
      const data = JSON.parse(responseText);
      
      if (data.error) {
        sendErrorUserMsg = data.error;
        sendError = true;
        sendErrorMsg = data.error + (debugMode && data.debugError ? " " + data.debugError : "");
      }
    } catch (error) {
      console.error(responseText);
      console.error(error);
      sendError = true;
      sendErrorMsg = error;
    }

    // if error has occured
    if (sendError) {
      let errorMessage = "An error occurred. Please try again.";

      if (sendErrorUserMsg) { // use custom error message from server if provided
        errorMessage = sendErrorUserMsg;
      }
    }
  }

  // update bot details
  const updateBot = async (botId, botDetails) => {
    let responseText = null;

    // error variables
    let sendError = false;
    let sendErrorMsg = null;
    let sendErrorUserMsg = null;

    try {
      // updateBot request arguments
      const body = {
        action: 'updateBot',
        botId: botId,
        botDetails, botDetails
      };
      if (requestedUser) {
        body.user = requestedUser; // requested user group
      }
      if (group) {
        body.group = group; // requested user group
      }
      if (extend) {
        body.extend = true; // TODO: remove - for testing only
      }

      if (sessionId) {
        body.sessionId = sessionId; // for local debugging, not used on server
      }
      if (debugMode) {
        body.debug = debugMode; // TODO: remove - for testing only
      }
      
      // send updateBot request to server
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
      });
      
      responseText = await response.text();
      const data = JSON.parse(responseText);

      if (debugMode) {
        console.log('updateBot RESPONSE data:', data);
        setDebugText(data.debugString);
      }

      setSavingBot(false);

      if (data.success) {
        loadBot(bot.id);
        loadBots();
        setBotEditViewShown(false);
      } else {
        if (data.error) {
          displayError(data.error);
        }
      }
      
      if (data.error) {
        sendErrorUserMsg = data.error;
        sendError = true;
        sendErrorMsg = data.error + (debugMode && data.debugError ? " " + data.debugError : "");
      }
    } catch (error) {
      console.error(responseText);
      console.error(error);
      sendError = true;
      sendErrorMsg = error;
    }

    // if error has occured
    if (sendError) {
      let errorMessage = "An error occurred. Please try again.";

      if (sendErrorUserMsg) { // use custom error message from server if provided
        errorMessage = sendErrorUserMsg;
      }
    }
  }

  // create bot
  const createBot = async (botDetails) => {
    let responseText = null;

    // error variables
    let sendError = false;
    let sendErrorMsg = null;
    let sendErrorUserMsg = null;

    try {
      // updateBot request arguments
      const body = {
        action: 'createBot',
        botDetails, botDetails
      };
      if (requestedUser) {
        body.user = requestedUser; // requested user group
      }
      if (group) {
        body.group = group; // requested user group
      }
      if (extend) {
        body.extend = true; // TODO: remove - for testing only
      }

      if (sessionId) {
        body.sessionId = sessionId; // for local debugging, not used on server
      }
      if (debugMode) {
        body.debug = debugMode; // TODO: remove - for testing only
      }
      
      // send updateBot request to server
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
      });
      
      responseText = await response.text();
      const data = JSON.parse(responseText);

      if (debugMode) {
        console.log('createBot RESPONSE data:', data);
        setDebugText(data.debugString);
      }

      setSavingBot(false);

      if (data.success) {
        loadBot(data.bot.id);
        loadBots();
        setBotEditViewShown(false);
      } else {
        if (data.error) {
          displayError(data.error);
        }
      }
      
      if (data.error) {
        sendErrorUserMsg = data.error;
        sendError = true;
        sendErrorMsg = data.error + (debugMode && data.debugError ? " " + data.debugError : "");
      }
    } catch (error) {
      console.error(responseText);
      console.error(error);
      sendError = true;
      sendErrorMsg = error;
    }

    // if error has occured
    if (sendError) {
      let errorMessage = "An error occurred. Please try again.";

      if (sendErrorUserMsg) { // use custom error message from server if provided
        errorMessage = sendErrorUserMsg;
      }
    }
  }

  // load bots
  const loadBots = async () => {
    let responseText = null;

    // error variables
    let sendError = false;
    let sendErrorMsg = null;
    let sendErrorUserMsg = null;

    try {
      // loadBots request arguments
      const body = {
        action: 'loadBots'
      };
      if (requestedUser) {
        body.user = requestedUser; // requested user group
      }
      if (group) {
        body.group = group; // requested user group
      }
      if (extend) {
        body.extend = true; // TODO: remove - for testing only
      }

      if (sessionId) {
        body.sessionId = sessionId; // for local debugging, not used on server
      }
      if (debugMode) {
        body.debug = debugMode; // TODO: remove - for testing only
      }
      
      // send loadBots request to server
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
      });
      
      responseText = await response.text();
      const data = JSON.parse(responseText);

      if (data.bots) {
        setBots(data.bots); // set list of bots
      }
      if (data.userBots) {
        setUserBots(data.userBots); // set list of user bots
      }
      if (data.groupBots) {
        setGroupBots(data.groupBots); // set list of user bots
      }
      
      if (data.error) {
        sendErrorUserMsg = data.error;
        sendError = true;
        sendErrorMsg = data.error + (debugMode && data.debugError ? " " + data.debugError : "");
      }
    } catch (error) {
      console.error(responseText);
      console.error(error);
      sendError = true;
      sendErrorMsg = error;
    }

    // if error has occured
    if (sendError) {
      let errorMessage = "An error occurred. Please try again.";

      if (sendErrorUserMsg) { // use custom error message from server if provided
        errorMessage = sendErrorUserMsg;
      }
    }
  }

  useEffect(() => {
    if (!hasLoaded.current) {
      getSession();
      hasLoaded.current = true;
    }
  }, [getSession]);

  return {
    user,
    curModel,
    messages,
    bot,
    bots,
    userBots,
    groupBots,
    conversation,
    conversations,
    sendMessage,
    newChat,
    selectBot,
    selectBotById,
    selectBotByName,
    selectConversation,
    updateBotUserPrefs,
    updateUserSettings,
    updateBot,
    createBot,
    debugText
  };
};

export default useChat;