import React from 'react';
import BotListItem from './BotListItem';
import './style.css';

const BotsView = ({ bots, curBot, selectBot }) => (
  <div className="bots-view">
    {bots.filter((bot) => bot !== null).map((bot) => (
      <BotListItem key={bot.id} bot={bot} onClick={() => selectBot(bot)} isActive={curBot && curBot.id === bot.id} />
    ))}
  </div>
);

export default BotsView;