import React, { useState, useEffect, useRef } from 'react';
import './style.css';

import ProfileImage from '../ProfileImage';

import { ReactComponent as LoadingGraphic } from '../../assets/icons/loading.svg';

const profileImageContext = require.context('../../assets/profile-images/', false, /\.(png|jpe?g|svg)$/);
const numProfileImages = profileImageContext.keys().length;

const BotEditView = ({
  creatingBot,
  curBot,
  isAdmin,
  saving,
  triggerSaveBot,
  triggerClose
}) => {

  let curIdName = curBot ? curBot.idName : '';

  if (curIdName) {
    // if slash exists remove group prefix preceding slash
    let groupSeparatorIndex = curIdName.indexOf('/');
    if (groupSeparatorIndex !== -1) {
      curIdName = curIdName.substring(groupSeparatorIndex + 1);
    }
  }

  const curSettings = (curBot && curBot.settings && typeof curBot.settings === 'object' && !Array.isArray(curBot.settings)) ? curBot.settings : null;

  //get array of systems prompts as defined by each new line of systemPrompts
  const curSystemPrompts = (curBot && curBot.systemPrompts) ? curBot.systemPrompts.split('\n') : null;
  // get first system prompt as defined by first line of systemPrompts
  let curSystemPrompt = (curSystemPrompts && curSystemPrompts.length >= 1) ? curSystemPrompts[0] : '';
  // replace all instances of the string '\n' with new line character
  curSystemPrompt = curSystemPrompt.replace(/\\n/g, '\n')

  const curPluginsList = (curBot && curBot.plugins && curBot.plugins.plugins) ? curBot.plugins.plugins : null;
  const curEnabledPlugins = curPluginsList ? curPluginsList.filter(plugin => plugin.enabled !== false).map(plugin => plugin.name) : null;
  const curBotEnableMemory = !!(curEnabledPlugins && curEnabledPlugins.includes('memory'));
  const curBotEnableImageGeneration = !!(curEnabledPlugins && curEnabledPlugins.includes('imageGeneration'));

  const [botDetails, setBotDetails] = useState((creatingBot || !curBot) ? {
    name: '',
    idName: '',
    image_index: Math.floor(Math.random() * (numProfileImages - 1)) + 1,
    description: '',
    systemPrompts: '',
    systemPrompt: '',
    welcomeMessages: '',
    useNewChatMessages: false,
    newChatMessages: '',
    basicInfoAccess: true,
    enableMemory: false,
    enableImageGeneration: false,
    public: false,
    publicListed: false
  } : {
    name: curBot.name,
    idName: curIdName,
    image_index: curBot.image_index,
    description: curBot.description,
    systemPrompts: curBot.systemPrompts, // store systemPrompts to be used when saving
    systemPrompt: curSystemPrompt,
    welcomeMessages: curBot.welcomeMessages,
    settings: curSettings, // store settings to be used when saving
    useNewChatMessages: curSettings ? !!curSettings.useNewChatMessages : false, // ensure useNewChatMessages is converted to boolean
    newChatMessages: curBot.newChatMessages,
    basicInfoAccess: curBot.basicInfoAccess,
    enableMemory: curBotEnableMemory,
    enableImageGeneration: curBotEnableImageGeneration,
    public: curBot.public,
    publicListed: curBot.publicListed
  });

  const saveLabel = creatingBot ? 'Create Bot' : 'Save Bot';

  // store if idName has been modified by user to avoid auto updating idName when name is changed
  const [isIdNameModified, setIsIdNameModified] = useState(!creatingBot); // set isIdNameModified to true if editing existing bot

  const handleInputChange = (e) => {
      const { id, value } = e.target;
      
      // if name is being initially set and idName has not been modified, update idName automaticlally
      if (id === 'name' && !isIdNameModified) {
        const transformedValue = value
          .toLowerCase()
          .replace(/[^a-z0-9\s]/g, '')
          .replace(/\s+/g, '-');

        // set name value and idName to automatic value
        setBotDetails((prevValue) => ({ ...prevValue, name: value, idName: transformedValue }));
      } else {
        if (id === 'idName') { // if is idName field, set isIdNameModified to true to avoid auto updating
          setIsIdNameModified(true);
        }

        // update field value
        setBotDetails((prevValue) => ({ ...prevValue, [id]: value }));
      }
      
  };
  
  const handleCheckBoxChange = (e) => {
      const { id, checked } = e.target;
      setBotDetails({ ...botDetails, [id]: checked });
  };

  const validateBotDetails = (botDetails) => {
    if (!botDetails.name) {
      window.alert('Bot Name is required.')
      return false;
    }

    // trim any spaces from idName
    botDetails.idName = botDetails.idName.trim();

    if (!botDetails.idName) {
      window.alert('Bot ID is required.')
      return false;
    }

    // show error if idName contains invalid characters
    const invalidIdCharacters = /[^A-Za-z0-9-_]/;
    if (invalidIdCharacters.test(botDetails.idName)) {
      window.alert('Bot ID must only use letters, numbers, dashes or underscores and contain no spaces')
      return false;
    }

    return true;
  }
  
  const handleSaveBot = () => {
    if (validateBotDetails(botDetails)) {
      // replace all new line characters with string '\n' to be stored in database
      const systemPromptString = botDetails.systemPrompt.replace(/\n/g, '\\n')
      // update systemPrompts stored on separately lines with new first systemPrompt
      let systemPrompts = botDetails.systemPrompts ? botDetails.systemPrompts.split('\n') : [''];
      systemPrompts[0] = systemPromptString;
      botDetails.systemPrompts = systemPrompts.join('\n');

      botDetails.settings = botDetails.settings || {};
      botDetails.settings.useNewChatMessages = botDetails.useNewChatMessages;
      
      triggerSaveBot(botDetails);
    }
  };

  const handleProfileImageClick = () => {
    let newImageIndex = botDetails.image_index + 1;

    if (newImageIndex > numProfileImages) {
      newImageIndex = 1;
    }
    setBotDetails({ ...botDetails, image_index: newImageIndex });
  };

  return (
    <div className={`bot-edit-view ${creatingBot ? 'full-screen' : ''}`}>
      <div className="bot-edit-view-container">
        <div className="header">
          <div className="left-controls">
            <button className="button cancel-button" onClick={triggerClose}>
              <div className="button-label">Cancel</div>
            </button>
          </div>
          <div className="right-controls">
            <button className="button save-button" onClick={handleSaveBot}>
              <div className="button-label">{saveLabel}</div>
            </button>
          </div>
        </div>
        <div className="bot-edit-view-content">
          <div className="field">
            <div className="label" onClick={handleProfileImageClick}>Profile Image<span className="note"> - tap to change</span></div>
            <ProfileImage bot={botDetails} onClick={handleProfileImageClick} />
          </div>
          <div className="field">
            <div className="label">Bot Name</div>
            <input
              type="text"
              id="name"
              value={botDetails.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="field">
            <div className="label">Bot ID <span className="note"> - as appears in URL</span></div>
            <input
              type="text"
              id="idName"
              value={botDetails.idName}
              onChange={handleInputChange}
            />
          </div>
          <div className="field">
            <div className="label">Tagline<span className="note"> - displayed under bot name</span></div>
            <input
              type="text"
              id="description"
              value={botDetails.description}
              onChange={handleInputChange}
            />
          </div>
          <div className="field">
            <div className="label">System Prompt<span className="note"> - describe the intent of your bot, e.g. "You are a marketing consultant. You provide..."</span></div>
            <textarea
              id="systemPrompt"
              value={botDetails.systemPrompt}
              onChange={handleInputChange}
              rows="10"
            />
          </div>
          <div className="field">
            <div className="label">Welcome Messages <span className="note"> -  initial randomly selected welcome message from the bot. separate each option on a new line, leave this blank for no initial message</span></div>
            <textarea
              id="welcomeMessages"
              value={botDetails.welcomeMessages}
              onChange={handleInputChange}
              rows="6"
            />
          </div>
          <div className="field">
            <div className="checkbox">
              <input
                type="checkbox"
                id="useNewChatMessages"
                checked={botDetails.useNewChatMessages}
                onChange={handleCheckBoxChange}
              />
              <label htmlFor="useNewChatMessages" className="label">Use different initial message for new chats</label>
            </div>
          </div>
          {botDetails.useNewChatMessages && <div className="field">
            <div className="label">New Chat Messages <span className="note"> - randomly selected message from the bot for new chats. separate each option on a new line, leave this blank for no initial message for new chats</span></div>
            <textarea
              id="newChatMessages"
              value={botDetails.newChatMessages}
              onChange={handleInputChange}
              rows="6"
            />
          </div>}
          <div className="field">
            <div className="checkbox">
              <input
                type="checkbox"
                id="basicInfoAccess"
                checked={botDetails.basicInfoAccess}
                onChange={handleCheckBoxChange}
              />
              <label htmlFor="basicInfoAccess" className="label">Provide basic context<span className="note"> - give bot awareness of it's name and the current time</span></label>
            </div>
          </div>
          <div className="field">
            <div className="checkbox">
              <input
                type="checkbox"
                id="enableMemory"
                checked={botDetails.enableMemory}
                onChange={handleCheckBoxChange}
              />
              <label htmlFor="enableMemory" className="label">Enable Memory<span className="note"> - remember older messages contextually relevant to recent messages</span></label>
            </div>
          </div>
          <div className="field">
            <div className="checkbox">
              <input
                type="checkbox"
                id="enableImageGeneration"
                checked={botDetails.enableImageGeneration}
                onChange={handleCheckBoxChange}
              />
              <label htmlFor="enableImageGeneration" className="label">Enable Image Generation<span className="note"> - ability to generate images</span></label>
            </div>
          </div>
          <div className="field">
            <div className="checkbox">
              <input
                type="checkbox"
                id="public"
                checked={botDetails.public}
                onChange={handleCheckBoxChange}
              />
              <label htmlFor="public" className="label">Public <span className="note"> - accessible publicly via URL</span></label>
            </div>
          </div>
          {isAdmin ? <div className="field">
            <div className={`checkbox ${!botDetails.public ? 'disabled' : ''}`}>
              <input
                type="checkbox"
                id="publicListed"
                checked={botDetails.publicListed}
                onChange={handleCheckBoxChange}
                disabled={!botDetails.public}
              />
              <label htmlFor="publicListed" className="label">Publicly Listed</label>
            </div>
          </div> : null}
        </div>
        <div className="header">
          <div className="left-controls">
            <button className="button cancel-button" onClick={triggerClose}>
              <div className="button-label">Cancel</div>
            </button>
          </div>
          <div className="right-controls">
            <button className="button save-button" onClick={handleSaveBot}>
              <div className="button-label">{saveLabel}</div>
            </button>
          </div>
        </div>
        {saving ? <div className="loading-overlay">
          <div className="message-loading-graphic-container">
            <LoadingGraphic className="message-loading-graphic" />
          </div>
        </div> : null}
      </div>
    </div>
  );
};

export default BotEditView;