import React from 'react';
import './style.css';

import ProfileImage from '../../ProfileImage';

const BotListItem = ({
  bot,
  onClick,
  isActive
}) => {
  const annotationLabel = (bot.publicListed) ? 'Publically Listed' + (bot.description ? ' - ' : '') : '';

  return (
    <div className={`bot-list-item ${ isActive ? 'active' : ''}`} onClick={onClick}>
      <ProfileImage bot={bot} />
      <div className="item-info">
        <div className="item-heading">
          <div className="item-heading-element">{bot.name}</div>&nbsp;
        </div>
        {(bot.description || annotationLabel) && <div className="item-description">
          <div className="item-description-element">
            {annotationLabel && <span className="item-info-annotation">{annotationLabel}</span>}
            {bot.description}
          </div>&nbsp;
        </div>}
      </div>
    </div>
  )
};

export default BotListItem;