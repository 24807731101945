import React from 'react';
import './style.css';

import { defaultChatName } from '../../../../config/locale';

const ConversationListItem = ({ conversation, isActive, onSelectConversation, number }) => {
  const handleClick = () => {
    onSelectConversation(conversation);
  };

  return (
    <div className={`conversation-list-item ${isActive ? 'active' :''}`} onClick={handleClick}>
      {conversation.name ? conversation.name : defaultChatName + ' ' + number}
    </div>
  );
};

export default ConversationListItem;