// src/MessageList.js
import React, { useEffect, useRef, useState } from 'react';
import './style.css';

import { ReactComponent as LoadingGraphic } from '../../assets/icons/loading.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/circle-info-solid.svg';

const MessageList = ({
  messages,
  model,
  bot,
  onErrorClick
}) => {
  const scrollContainerRef = useRef(null);
  // const messagesEndRef = useRef(null); // reference for element for smooth scrolling - doesn't currently work

  const messagesLoadWarning = "GPT-4 may take a few moments"; // due to it's advanced capability.";

  // flag if messages contain user message to add extra space to bottom of message list
  const hasUserMessage = messages.some(message => message.role === 'user');
  
  const previousMessagesLengthRef = useRef(0); // store previous messages length to determine if messages went from empty to not empty

  // get user selectable role label for message
  const getRoleLabel = (role) => {
    if (role === 'assistant') {
      if (bot && bot.name) {
        role = bot.name;
      }
    } else if (role === 'user') {
      role = 'me';
    }

    //capitalize first letter of role
    role = role.charAt(0).toUpperCase() + role.slice(1);
    return role;
  };

  // update scroll position to bottom on message list change
  const updateScroll = (forceScroll) => {
    const scrollContainer = scrollContainerRef.current;
    // const messagesEnd = messagesEndRef.current;
    
    // get previous scroll height
    const previousScrollHeight = scrollContainer.scrollHeight - scrollContainer.clientHeight;

    // check if previous scroll height is within last half of scroll container height
    const withinLastHalfHeightOfView = (Math.abs(scrollContainer.scrollTop - previousScrollHeight) <= scrollContainer.clientHeight / 2);

    // if force scroll or within last half of scroll container height, scroll to bottom
    if (forceScroll || withinLastHalfHeightOfView) {
      // TODO: perform smooth scroll
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
      
      // TODO: this technique does not seem to work consistently
      // messagesEnd.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  // on message list change, update scroll position
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    // const messagesEnd = messagesEndRef.current;

    const messagesWentFromEmptyToNotEmpty = messages && messages.length > 0 && previousMessagesLengthRef.current === 0;
    if (messagesWentFromEmptyToNotEmpty) {
      // TODO: perform smooth scroll
      scrollContainer.scrollTop = scrollContainer.scrollHeight;

      // TODO: this technique does not seem to work consistently
      // messagesEnd.scrollIntoView({ block: 'end' });
    } else {
      // check if last message is pending
      const messageJustSent = messages && messages.length && messages[messages.length - 1].isPending;
      
      updateScroll(messageJustSent); // force scroll if message just sent
      setTimeout(updateScroll, 200); // ensure scrolled to bottom - TODO: can remove?
    }
    
    if (messages) {
      previousMessagesLengthRef.current = messages.length; // store previous messages length for determining if messages went from empty to not empty
    }
  }, [messages]);

  // on mount, observe for DOM changes to update scroll position
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    
    // listens for DOM changes on scroll container (e.g. messages added or removed in DOM)
    // scroll does not seem to update reliabily if just listening to message changes
    const observer = new MutationObserver(() => {
      // scroll to bottom of scroll container
      updateScroll();
      setTimeout(updateScroll, 200); // TODO: is this necessary?
    });
    observer.observe(scrollContainer, { childList: true, subtree: true });
    
    return () => observer.disconnect();
  }, []);

  const [loadedImages, setLoadedImages] = useState([]);
  const [errorImages, setErrorImages] = useState([]);

  const handleImageLoad = (key) => {
    setLoadedImages((prevLoadedImages) => [...prevLoadedImages, key]);
  };
  const handleImageError = (key) => {
    setErrorImages((prevErrorImages) => [...prevErrorImages, key]);
  };

  return (
    <div ref={scrollContainerRef} className={`messages ${!hasUserMessage ? 'no-user-messages' : ''}`}>
    <div className ="messages-inner-spacer"></div>
      <div className ="messages-inner">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.role} ${message.isPending ? 'message-pending' : ''} ${message.appended ? 'appended' : ''} ${message.media ? 'hasMedia' : ''}`} onClick={message.role === 'error' ? onErrorClick : null}>
            {(message.role && message.role !== 'system' && message.role !== 'error') ? <div className="message-hidden-label"><br/>{getRoleLabel(message.role) + ':'}<br/></div> : null}
            {(message.isPending && model=="gpt-4") ? (
              <div className ="messages-load-info">
                <InfoIcon className="message-load-info-icon" />
                <div className="message-load-info-text">{messagesLoadWarning}</div>
              </div>
            ) : null}
            {!message.isPending
              ?
                <div className="message-content">
                  {message.content && <div className="message-text">{message.content.trim()}</div>}
                  {message.media && <div className="message-media">
                    {message.media.map((media, index) => (
                      media.type === 'image' && <div key={index} className="message-media-image">
                        {!loadedImages.includes(media.url) && !errorImages.includes(media.url) && <div className="message-loading-graphic-container">
                          <LoadingGraphic className="message-loading-graphic" />
                        </div>}
                        {!errorImages.includes(media.url) ?
                          <img alt={media.description} src={media.url} onLoad={() => handleImageLoad(media.url)} onError={() => handleImageError(media.url)} />
                        :
                          <div className="message-media-image-error">Image no longer available</div>
                        }
                      </div>
                    ))}
                  </div>}
                </div>
              :
                <div className="message-loading-graphic-container">
                  <LoadingGraphic className="message-loading-graphic" />
                </div>
            }
          </div>
        ))}
      </div>
      {/* <div className="messages-end-container">
        <div ref={messagesEndRef} className="messages-end-target"></div>
      </div> */}
    </div>
  );
}

export default MessageList;