import React, { useEffect, useRef } from 'react';
import './style.css';

import MenuItem from './MenuItem';

const Menu = ({
  curBot,
  settings,
  userBots,
  groupBots,
  onSelectMenuItem,
  triggerClosed
}) => {

  const menuViewContainerRef = useRef();

  const activeBot = !!curBot;

  const user = settings ? settings.user : null;
  // const canEditBots = user ? !!(user.isAdmin || user.canEditBots) : false;
  const canCreateBots = user ? !!(user.isAdmin || user.canCreateBots) : false;
  // const isLoggedInUser = user ? !!user.isLoggedIn : false;
  // const isAdmin = user ? !!user.isAdmin : false;
  // const userId = user ? user.id : null;

  // const isBotCreator = !!(isLoggedInUser && curBot && curBot.createdBy && curBot.createdBy === userId); // TODO: use permissions for editing and creating bots
  const canEditBot = !!(curBot && curBot.canEdit);
  const canCreateBot = canCreateBots; // canCreateBots && !!(isLoggedInUser || isAdmin); // TODO: use permissions for editing and creating bots

  const baseMenuItems = [
    {
      action: 'darkMode',
      label: 'Dark Mode: ',
      value: (settings && settings.darkMode) ? 'On' : 'Off'
    },
    {
      action: 'editBot',
      label: 'Edit Bot',
      visible: activeBot && canEditBot
    },
    {
      action: 'createBot',
      label: 'Create Bot',
      visible: canCreateBot
    }
  ];

  const menuItems = [
    ...baseMenuItems
  ];

  // const userBots = ['Bot 1','Bot 2','Bot 3'];

  if (userBots && userBots.length > 0) {
    menuItems.push(
      {
        id: 'my-bots',
        label: 'My Bots',
        className: 'menu-list-label'
      }
    );
    userBots.forEach((bot) => {
      const botId = bot.id;
      const botName = bot.name;
      menuItems.push(
        {
          id: 'select-bot-' + botId,
          bot: bot,
          botId: botId,
          action: 'selectBot',
          label: botName
        }
      );
    });
  }

  
  if (groupBots && groupBots.length > 0) {
    menuItems.push(
      {
        id: 'group-bots',
        label: 'Shared Bots',
        className: 'menu-list-label'
      }
    );
    groupBots.forEach((bot) => {
      const botId = bot.id;
      const botName = bot.name;
      menuItems.push(
        {
          id: 'select-bot-' + botId,
          bot: bot,
          botId: botId,
          action: 'selectBot',
          label: botName
        }
      );
    });
  }
  
  // on click outside of conversation list, close conversation list
  const handleWindowClick = (event) => {
    // if clicking outside menu
    if (!menuViewContainerRef.current || !menuViewContainerRef.current.contains(event.target)) {
      triggerClosed();
      event.preventDefault();
      event.stopPropagation();
    }
  }
  
  // on mount, add click and touch listeners to document for closing conversation list
  useEffect(() => {
    document.addEventListener('click', handleWindowClick);

    return () => {
      document.removeEventListener('click', handleWindowClick);
    };
  }, []);

  return (
    <div className="menu-view">
      <div className="menu-view-container" ref={menuViewContainerRef}>
        {menuItems.filter((menuItem) => menuItem.visible !== false).map((menuItem) => (
          <MenuItem
            curBot={curBot}
            key={menuItem.id || menuItem.action}
            menuItem={menuItem}
            onSelect={onSelectMenuItem}
            className={menuItem.className}
          />
        ))}
      </div>
    </div>
  );
};

export default Menu;